import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="42" height="31" viewBox="0 0 42 31" {...props}>
      <path
        d="M41.6812 15.274C41.6812 10.574 40.9392 6.75806 40.0242 6.75806C39.1092 6.75806 38.3672 10.571 38.3672 15.274C38.3672 19.977 39.1092 23.7901 40.0242 23.7901C40.9392 23.7901 41.6812 19.978 41.6812 15.274Z"
        fill="#E34561"
      />
      <path
        opacity="0.2"
        d="M38.3672 15.2751C38.3672 15.4151 38.3672 15.5547 38.3672 15.6941C38.6085 15.2612 38.9178 14.8698 39.2832 14.5351C40.1392 14.9601 40.9832 15.37 41.6762 15.756C41.6762 15.5967 41.6762 15.4367 41.6762 15.2761C41.6788 13.9305 41.612 12.5857 41.4762 11.2471C40.4112 10.3381 39.5922 9.61206 38.8762 9.09106C38.5096 11.1314 38.3392 13.2022 38.3672 15.2751Z"
        fill="#90374B"
      />
      <path
        opacity="0.2"
        d="M38.7616 20.7861C38.8523 21.37 38.9826 21.947 39.1516 22.5131C39.9956 21.8554 40.7248 21.0622 41.3096 20.1661C41.8726 19.2731 39.1886 17.8091 38.3936 16.7811C38.4196 17.5191 38.4636 18.2241 38.5236 18.8811C38.6536 18.9471 38.7796 19.0101 38.8996 19.0671C39.9106 19.5461 39.3686 20.2771 38.7616 20.7861Z"
        fill="#90374B"
      />
      <path
        opacity="0.2"
        d="M40.3097 23.6631C40.1287 23.6681 39.9467 23.6741 39.7637 23.6841C39.836 23.7489 39.9286 23.7864 40.0257 23.7901C40.1328 23.7843 40.2339 23.7391 40.3097 23.6631Z"
        fill="#90374B"
      />
      <path
        d="M22.8418 22.5645C26.9958 18.4105 26.9958 11.6755 22.8418 7.52147C18.6878 3.36747 11.9528 3.36747 7.79884 7.52147C3.64483 11.6755 3.64483 18.4105 7.79884 22.5645C11.9528 26.7185 18.6878 26.7185 22.8418 22.5645Z"
        fill="#E34561"
      />
      <path
        opacity="0.2"
        d="M13.2458 24.889C11.9178 24.695 9.24579 23.639 8.43079 22.617C7.85579 21.895 17.9368 21.479 23.3878 21.994C23.7686 21.5532 24.1128 21.082 24.4168 20.585C24.3548 20.6 24.2968 20.6161 24.2438 20.6331C21.9198 19.9331 15.9038 20.8711 13.9948 20.7631C13.1068 20.7131 8.00979 19.8631 8.38579 19.4351C8.76179 19.0071 6.12179 17.5601 4.75879 16.1801C4.86301 17.1668 5.10573 18.134 5.47979 19.053C6.10379 19.168 6.54979 19.305 6.62179 19.585C6.70079 19.885 6.43479 20.122 6.13479 20.385C7.46965 22.6824 9.61546 24.3974 12.1505 25.1931C14.6856 25.9887 17.4266 25.8076 19.8348 24.6851C16.7768 24.6651 13.5198 24.886 13.2458 24.889Z"
        fill="#90374B"
      />
      <path
        opacity="0.2"
        d="M7.21605 11.85C6.52306 12.0968 5.79773 12.241 5.06305 12.278C4.90934 12.8505 4.80397 13.4349 4.74805 14.025C6.30405 13.225 9.76605 12.335 10.165 11.584C10.487 10.978 14.365 12.469 15.134 12.612C15.488 12.844 15.972 13.13 16.614 13.491C18.714 14.17 20.331 13.457 21.239 13.583C22.7795 13.8076 24.3403 13.8579 25.892 13.733C25.841 13.3189 25.7656 12.9082 25.666 12.503C24.795 12.475 23.739 12.392 22.694 12.255C23.7521 11.913 24.8311 11.347 23.9711 11.055C23.0291 10.735 17.055 7.92096 15.833 7.67596C14.733 7.45596 12.918 6.18998 11.072 6.20898C10.641 6.20898 10.101 6.18699 9.52505 6.13599C8.96435 6.50258 8.43938 6.92109 7.95705 7.38599C10.421 7.67899 13.679 8.03597 14.27 8.14597C16.064 8.47697 22.955 10.605 21.519 12.1C19.926 11.917 15.888 11.5 15.519 11.54C15.269 11.567 15.049 11.592 14.867 11.622C13.43 11.048 10.945 9.50797 8.67205 9.32797C8.27205 9.29597 7.38005 9.30497 6.35305 9.34497C6.03953 9.83767 5.76728 10.3554 5.53905 10.893C7.08905 10.865 8.34905 11.041 7.21605 11.85Z"
        fill="#90374B"
      />
      <path
        d="M37.133 15.043C37.133 9.7 34.855 5.36902 32.046 5.36902C29.237 5.36902 26.959 9.7 26.959 15.043C26.959 20.386 29.237 24.717 32.046 24.717C34.855 24.717 37.133 20.386 37.133 15.043Z"
        fill="#E34561"
      />
      <path
        opacity="0.2"
        d="M27.1863 13.4289C27.7743 13.1639 31.3803 14.929 31.9793 15.042C32.3513 15.11 34.7534 16.729 36.8954 17.995C37.0556 17.0259 37.1355 16.0452 37.1343 15.0629C37.1343 14.7443 37.1264 14.4293 37.1104 14.118C36.3494 14.295 35.4523 14.518 34.6213 14.67C33.6113 14.853 29.3214 11.9699 27.5034 12.4419C27.3847 12.4701 27.2639 12.4892 27.1423 12.499C27.0957 12.825 27.0573 13.1583 27.0273 13.499C27.0823 13.473 27.1353 13.4519 27.1863 13.4289Z"
        fill="#90374B"
      />
      <path
        opacity="0.2"
        d="M30.122 8.23996C29.5348 8.37117 28.9356 8.44089 28.334 8.44797C28.0683 8.99285 27.8453 9.55755 27.667 10.137C29.358 10.397 31.467 10.481 31.738 9.81396C32.383 8.21996 34.365 11.304 35.487 12.301C35.9739 12.7103 36.4993 13.0713 37.056 13.379C36.9202 11.4508 36.3747 9.57366 35.456 7.87296C33.961 7.40296 31.679 6.44896 30.328 5.95496C29.6112 6.4819 29.0217 7.16297 28.603 7.94797C29.0991 8.09267 29.6076 8.19043 30.122 8.23996Z"
        fill="#90374B"
      />
      <path
        opacity="0.2"
        d="M32.812 22.169C32.428 22.409 30.12 21.602 28.04 21.021C28.4627 22.1619 29.1619 23.18 30.075 23.984C30.0708 23.7064 30.0329 23.4305 29.962 23.162C31.2646 23.4445 32.6097 23.4703 33.9221 23.238C34.2301 23.1588 34.5469 23.1185 34.8651 23.118C35.2666 22.5979 35.6056 22.0323 35.8751 21.433C34.8102 21.4324 33.7604 21.6846 32.812 22.169Z"
        fill="#90374B"
      />
      <path
        d="M40.4053 17.966C39.8053 18.01 39.1393 18.016 38.4473 18.003C38.4733 18.409 38.5053 18.803 38.5473 19.178C39.1813 19.225 39.8893 19.288 40.6863 19.369C40.9163 19.393 41.1793 19.428 41.4693 19.469C41.5243 18.969 41.5753 18.39 41.6103 17.818C41.2033 17.859 40.7993 17.937 40.4053 17.966Z"
        fill="#F0DEDA"
      />
      <path
        d="M27.831 19.991L27.667 20.02C27.9848 21.0733 28.4773 22.0659 29.124 22.956C31.635 22.411 33.468 21.428 33.333 20.645C33.185 19.786 30.721 19.493 27.831 19.991Z"
        fill="#F0DEDA"
      />
      <path
        d="M25.345 11.499C24.1438 12.0024 22.9893 12.6108 21.895 13.317C19.7322 14.7345 17.6917 16.3304 15.795 18.088C13.247 20.44 11.851 23.047 11.751 25.066C14.2644 25.957 17.0203 25.8745 19.476 24.835C19.334 24.789 19.192 24.74 19.046 24.688C16.538 23.776 18.27 18.722 22.156 15.136C22.793 14.548 24.246 13.386 25.606 12.336C25.5293 12.0527 25.4423 11.7737 25.345 11.499Z"
        fill="#F0DEDA"
      />
      <path
        d="M35.5221 15.319C34.8591 15.213 36.1151 14.397 37.0771 13.441C37.0631 13.277 37.0471 13.1146 37.0291 12.954C36.0131 13.959 34.1191 15.234 35.0371 15.54C35.7606 15.7664 36.4482 16.0947 37.0791 16.515C37.0978 16.2943 37.1121 16.071 37.1221 15.845C36.623 15.579 36.0817 15.401 35.5221 15.319Z"
        fill="#F0DEDA"
      />
      <path
        d="M36.7437 11.235C34.1797 11.756 28.3836 15.09 27.7986 13.415C27.2336 11.797 30.2746 7.64402 31.5256 5.36902C29.0316 5.85902 27.0737 9.77501 26.9727 14.6L26.9996 14.621C29.5606 16.376 34.7506 11.865 36.8286 11.645C36.802 11.507 36.7737 11.3703 36.7437 11.235Z"
        fill="#F0DEDA"
      />
      <path
        d="M38.6025 10.807C39.4524 11.0615 40.2788 11.3887 41.0725 11.785C41.2305 11.864 41.3945 11.9511 41.5615 12.0421C41.5365 11.7181 41.5075 11.403 41.4755 11.1C41.2755 10.987 41.0755 10.872 40.8575 10.754C40.1762 10.3862 39.4678 10.071 38.7385 9.81104C38.6895 10.126 38.6435 10.459 38.6025 10.807Z"
        fill="#F0DEDA"
      />
      <path
        d="M23.7345 8.53595C23.6365 8.40928 23.5365 8.28527 23.4345 8.16394C22.9598 8.57788 22.405 8.88969 21.8045 9.07996C18.1355 10.256 16.8715 9.98696 13.4245 10.934C10.6501 11.6551 8.16578 13.2147 6.31054 15.4C5.73301 16.1778 5.32181 17.0663 5.10254 18.0099C5.57218 19.6236 6.41802 21.1028 7.57054 22.326C6.80085 20.4661 6.79726 18.3774 7.56054 16.515C8.68554 14.207 11.3695 12.4469 14.2935 11.5269C16.2745 10.9039 19.4055 10.505 21.9245 9.60696C22.5927 9.37309 23.2079 9.00906 23.7345 8.53595Z"
        fill="#F0DEDA"
      />
    </Svg>
  )
}

export default Icon
